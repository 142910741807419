export const MainPageRoute = String('/')
export const LandingPageRoute = MainPageRoute
export const InvitationFormPageRoute = String(':code/:token')

export const WelcomeRoute = String('welcome')
export const InvitationByPostMailRoute = String('welcome/mail')
export const InvitationByCardRoute = String('welcome/card')
export const InvitationByEmailRoute = String('welcome/email')
export const DownloadAppRoute = String('download-app')
export const InvitationAlreadyConfirmedRoute = String('already-confirmed')
export const ErrorRoute = String('error')
export const SiteMapRoute = String('site-map')
