import { isValidPhoneNumber } from 'libphonenumber-js'

import { CountryCode } from '@i18n/detect-locale'

export const PASSWORD_MINIMUM_LENGTH = 8

export const hasEightCharactersMin = (password: string): boolean => password?.length >= PASSWORD_MINIMUM_LENGTH

export const hasOneCapitalLetterMin = (password: string): boolean => /[A-Z]/.test(password)

export const hasOneLowerCaseLetterMin = (password: string): boolean => /[a-z]/.test(password)

export const hasOneNumberMin = (password: string): boolean => /[0-9]/.test(password)

export const hasOneSpecialCharacterMin = (password: string): boolean =>
  /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(password)

export const passwordAllRules = (password: string): boolean =>
  hasEightCharactersMin(password) &&
  hasOneCapitalLetterMin(password) &&
  hasOneLowerCaseLetterMin(password) &&
  hasOneNumberMin(password) &&
  hasOneSpecialCharacterMin(password)

export const phoneNumbersAllRules = (phoneNumbers: string, countryCode: CountryCode): boolean =>
  isValidPhoneNumber(phoneNumbers, countryCode)

export const isMatchingEmailOrMailInvitationUrl = (code?: string, token?: string): boolean =>
  Boolean(code?.match(/^\w{12}$/) && token?.match(/^\w{12}$/))

export const isMatchingCardInvitationUrl = (code?: string, token?: string): boolean =>
  // a regex to math only 4 digits string
  Boolean(code?.match(/^\d{4}$/) && token?.match(/^\d{9,10}$/))
