import { useEffect } from 'react'

import { useLocation } from 'react-router'

import { useIsInvitationFormFlow } from '@app/hooks/useIsInvitationFormFlow'
import { TRACKING_FLOW_TYPE, TRACKING_STEP, trackPage } from '@app/utils/tracking'

export const useLocationChange = (): void => {
  const location = useLocation()
  const { isInvitationFormFlow, origin } = useIsInvitationFormFlow()

  useEffect(() => {
    if (isInvitationFormFlow) {
      trackPage(`${location.pathname}/${TRACKING_FLOW_TYPE[origin]}/${TRACKING_STEP['IDENTITY']}`)
      return
    }
    trackPage(location.pathname)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
}
