import { StrictMode } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import * as Sentry from '@sentry/react'
import './index.css'
import { createRoot } from 'react-dom/client'

import { App } from '@app/App'

const { VITE_APP_MODE, REACT_APP_DATADOG_TOKEN } = import.meta.env

const environment = import.meta.env.VITE_APP_MODE
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment,
  tracesSampleRate: 1.0,
})

datadogLogs.init({
  clientToken: REACT_APP_DATADOG_TOKEN || '',
  env: VITE_APP_MODE,
  service: 'invitations-webapp',
  site: 'datadoghq.eu',
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
})

const root = document.getElementById('root')

if (root === null) throw new Error('Root container missing in index.html')

createRoot(root).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
