import { useMatch } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { flowType, FlowType } from '@app/hooks/useSteps'
import { InvitationFormPageRoute } from '@app/routes'
import { isMatchingCardInvitationUrl, isMatchingEmailOrMailInvitationUrl } from '@app/utils/validators'

type UseIsInvitationFormFlowReturnType = {
  isInvitationFormFlow: boolean
  origin: FlowType
}

export const useIsInvitationFormFlow = (): UseIsInvitationFormFlowReturnType => {
  const invitationFormFlowMatch = useMatch(InvitationFormPageRoute)

  const [searchParams] = useSearchParams()
  const origin = flowType.includes(searchParams.get('origin') || '') ? searchParams.get('origin') : 'EMAIL'
  const trackingDetails = {
    origin: origin as FlowType,
  }

  if (!invitationFormFlowMatch) {
    return { ...trackingDetails, isInvitationFormFlow: false }
  }

  const { code, token } = invitationFormFlowMatch.params
  const isInvitationFormFlow =
    isMatchingEmailOrMailInvitationUrl(code, token) || isMatchingCardInvitationUrl(code, token)

  return { ...trackingDetails, isInvitationFormFlow }
}
