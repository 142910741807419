import { FC, PropsWithChildren } from 'react'

export const AppLayoutOneColumn: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="grid h-dvh grid-cols-1 justify-center bg-background-default not-supports-dvh:h-full dark:bg-background-default-dark"
      data-testid="layout-one-column"
    >
      {children}
    </div>
  )
}
