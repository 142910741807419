import React, { FC, lazy } from 'react'

import * as Sentry from '@sentry/react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'

import { useLocationChange } from './hooks/useLocationChange'
import { AppLayoutOneColumn } from './layout/AppLayoutOneColumn'
import {
  DownloadAppRoute,
  ErrorRoute,
  InvitationAlreadyConfirmedRoute,
  InvitationFormPageRoute,
  LandingPageRoute,
  InvitationByPostMailRoute,
  InvitationByEmailRoute,
  WelcomeRoute,
  InvitationByCardRoute,
  SiteMapRoute,
} from '@app/routes'

const ErrorPage = lazy(() =>
  import('@app/pages/ErrorPage').then((module) => ({
    default: module.ErrorPage,
  })),
)

const InvitationAlreadyConfirmedPage = lazy(() =>
  import('@app/pages/InvitationAlreadyConfirmedPage').then((module) => ({
    default: module.InvitationAlreadyConfirmedPage,
  })),
)

const WelcomePage = lazy(() =>
  import('@app/pages/WelcomePage').then((module) => ({
    default: module.WelcomePage,
  })),
)
const InvitationFormPage = lazy(() =>
  import('@app/pages/InvitationFormPage').then((module) => ({
    default: module.InvitationFormPage,
  })),
)

const PostMailFormPage = lazy(() =>
  import('@app/pages/PostMailFormPage').then((module) => ({
    default: module.PostMailFormPage,
  })),
)
const CardFormPage = lazy(() =>
  import('@app/pages/CardFormPage').then((module) => ({
    default: module.CardFormPage,
  })),
)
const EmailFormPage = lazy(() =>
  import('@app/pages/EmailFormPage').then((module) => ({
    default: module.EmailFormPage,
  })),
)
const DownloadAppPage = lazy(() =>
  import('@app/pages/DownloadAppPage').then((module) => ({
    default: module.DownloadAppPage,
  })),
)
const SiteMapPage = lazy(() =>
  import('@app/pages/SiteMapPage').then((module) => ({
    default: module.SiteMapPage,
  })),
)
const NotFoundPage = lazy(() =>
  import('@app/pages/NotFoundPage').then((module) => ({
    default: module.NotFoundPage,
  })),
)

export const AppRoutes: FC = () => {
  useLocationChange()

  return useRoutes([
    {
      path: LandingPageRoute,
      element: (
        <Sentry.ErrorBoundary
          fallback={({ error }) => (
            <AppLayoutOneColumn>
              <ErrorPage error={error as Error} origin="fallback sentry" />
            </AppLayoutOneColumn>
          )}
        >
          <AppLayoutOneColumn>
            <Outlet />
          </AppLayoutOneColumn>
        </Sentry.ErrorBoundary>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={WelcomeRoute} replace />,
        },
        {
          path: WelcomeRoute,
          element: <WelcomePage />,
        },
        {
          path: InvitationFormPageRoute,
          element: <InvitationFormPage />,
        },
        {
          path: InvitationByPostMailRoute,
          element: <PostMailFormPage />,
        },
        {
          path: InvitationByCardRoute,
          element: <CardFormPage />,
        },
        {
          path: InvitationByEmailRoute,
          element: <EmailFormPage />,
        },
        {
          path: DownloadAppRoute,
          element: <DownloadAppPage />,
        },
        {
          path: InvitationAlreadyConfirmedRoute,
          element: <InvitationAlreadyConfirmedPage />,
        },
        {
          path: ErrorRoute,
          element: <ErrorPage error={null} origin="routing" />,
        },
        {
          path: SiteMapRoute,
          element: <SiteMapPage />,
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
  ])
}
