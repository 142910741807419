import { Suspense, lazy, ReactElement } from 'react'

import { BrowserRouter } from 'react-router-dom'

import { Loader } from './components/Loader'
import { AppRoutes } from '@app/router'
import { Nullable } from '@app/types/common'

const Providers = lazy(() =>
  import('@app/components/Providers').then((module) => ({
    default: module.Providers,
  })),
)
export const App = (): Nullable<ReactElement> => {
  return (
    <Suspense fallback={<Loader />}>
      <Providers>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </Providers>
    </Suspense>
  )
}
