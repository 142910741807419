import { FC } from 'react'

export const Loader: FC = () => {
  return (
    <div>
      <span
        data-testid="loader"
        className="block h-[15px] w-full animate-shine !bg-[length:50px_500px] bg-[0_0] !bg-repeat-y"
        style={{
          background:
            'linear-gradient( to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ), lightgray',
        }}
      />
    </div>
  )
}
