import { useState } from 'react'

import { useLocation } from 'react-router'

import { useIsInvitationFormFlow } from '@app/hooks/useIsInvitationFormFlow'
import { TRACKING_FLOW_TYPE, TRACKING_STEP, trackPage } from '@app/utils/tracking'

export type Step = 'IDENTITY' | 'PASSWORD' | 'PHONE_NUMBER' | 'OTP'

export const flowType: string[] = ['CARD', 'EMAIL', 'MAIL'] as const
export type FlowType = (typeof flowType)[number]

export type useStepsNavigation = {
  currentStep: Step
  hasNextStep: boolean
  hasPreviousStep: boolean
  activeStepNumber: number
  reset: () => void
  goToNextStep: () => void
  goToPreviousStep: () => void
}

type UseStepsParams = {
  params?: {
    isUserSSO?: boolean
  }
  steps: Step[]
}

export const useSteps = ({ steps, params }: UseStepsParams): useStepsNavigation => {
  const filteredSteps = steps.filter((step) => {
    if (params?.isUserSSO) {
      return step !== 'PASSWORD'
    }

    return step
  })

  const [activeStepNumber, setActiveStepNumber] = useState(0)
  const hasNextStep = activeStepNumber < filteredSteps.length - 1
  const hasPreviousStep = activeStepNumber - 1 >= 0

  const location = useLocation()
  const { origin } = useIsInvitationFormFlow()

  return {
    reset: () => setActiveStepNumber(0),
    goToNextStep: () => {
      const newStepNumber = activeStepNumber + 1
      setActiveStepNumber(newStepNumber)

      trackPage(`${location.pathname}/${TRACKING_FLOW_TYPE[origin]}/${TRACKING_STEP[filteredSteps[newStepNumber]]}`)
    },
    goToPreviousStep: () => {
      const newStepNumber = activeStepNumber - 1
      setActiveStepNumber(newStepNumber)

      trackPage(`${location.pathname}/${TRACKING_FLOW_TYPE[origin]}/${TRACKING_STEP[filteredSteps[newStepNumber]]}`)
    },
    currentStep: filteredSteps[activeStepNumber],
    activeStepNumber,
    hasNextStep,
    hasPreviousStep,
  }
}
