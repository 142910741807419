import { FlowType, Step } from '@app/hooks/useSteps'

export const TRACKING_FLOW_TYPE: Record<FlowType, string> = {
  EMAIL: 'invitation_by_email',
  MAIL: 'invitation_by_mail',
  CARD: 'invitation_by_card',
}
export const TRACKING_STEP: Record<Step, string> = {
  IDENTITY: 'identity',
  PASSWORD: 'password',
  PHONE_NUMBER: 'phone_number',
  OTP: 'otp',
}

export const trackPage = (page: string, args?: unknown): void => {
  window._uxa?.push(['trackPageview', page])
}
